import { useState } from 'react';

import { alpha, Box, darken, useTheme } from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { numberFormat } from '../../../helpers/number';

interface IChartUniqueBar {
  name: string;
  value: number;
  avg?: number;
  max?: number;
  onClick?(): void;
  valuesOptions?: IChartBar['valuesOptions'];
}

interface IChartValue {
  name: string;
  value: number;
}
interface IChartBar {
  values: IChartValue[];
  max?: number;
  avg?: number;
  onClick?(event: any): void;
  valuesOptions?: Intl.NumberFormatOptions;
}

const ReferenceLineLabel = ({ value, ...props }: any) => {
  const theme = useTheme();
  const { viewBox } = props;

  return (
    <g
      height={18}
      preserveAspectRatio="xMidYMid meet"
      width="100%"
    >
      <rect
        fill={alpha(theme.palette.secondary.dark, 0.9)}
        height={18}
        radius={10}
        rx={2}
        width={50}
        x={viewBox.x - 50}
        y={(viewBox?.y ?? 0) - 18 / 2}
      />
      <text
        dominantBaseline="middle"
        fill="#fff"
        fontSize={11}
        style={{ textAlign: 'center' }}
        textAnchor="middle"
        width={50}
        x={viewBox.x - 50 / 2}
        y={viewBox.y}
      >
        {value}
      </text>
    </g>
  );
};

const ShapeChartBar = (props: any) => {
  const { fill, x, y, width, height, cursor } = props;

  const [isHover, setIsHover] = useState(false);

  return (
    <rect
      fill={cursor ? (!isHover ? fill : darken(fill, 0.2)) : fill}
      height={height}
      id="rect1"
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      rx={5}
      stroke="none"
      style={{ cursor, transition: 'fill 200ms ease-in-out' }}
      width={width}
      x={x}
      y={y}
    />
  );
};

const ShapeBarLabel = ({
  x,
  y,
  width,
  height,
  value,
  numberFormatOptions,
}: any) => {
  const theme = useTheme();

  return (
    <text
      dy={-6}
      fill={theme.palette.primary.main}
      fontSize={13}
      height={height}
      r={45}
      textAnchor="middle"
      x={x + width / 2}
      y={y}
    >
      {numberFormat(value, numberFormatOptions)}
    </text>
  );
};

export const ChartBar: React.FC<IChartBar> = ({
  values,
  max,
  avg,
  onClick,
  valuesOptions,
}) => {
  const theme = useTheme();

  return (
    <Box
      height={300}
      minWidth={120}
      width="100%"
    >
      <ResponsiveContainer
        height="100%"
        width="100%"
      >
        <BarChart
          barSize={30}
          data={values.map(({ name, value }) => ({
            name,
            value: +(value ?? 0).toFixed(3),
          }))}
          height={300}
          margin={{ right: 40, left: 20, bottom: 0, top: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            domain={[0, 'auto']}
            fontSize={11}
          />
          <YAxis
            domain={max ? [0, +(max ?? 0)] : undefined}
            fontSize={11}
            tickFormatter={(tick) => {
              if (!Number.isNaN(tick)) {
                if (tick >= 1000000) {
                  return `${numberFormat(tick, {
                    ...valuesOptions,
                    notation: 'compact',
                  })}`;
                }
                return numberFormat(tick, valuesOptions);
              }
              return tick;
            }}
          />
          <Bar
            dataKey="value"
            fill={theme.palette.primary.dark}
            label={(props: any) => (
              <ShapeBarLabel
                {...props}
                numberFormatOptions={valuesOptions}
              />
            )}
            minPointSize={10}
            onClick={(event) => {
              onClick?.(event);
            }}
            // onMouseEnter={console.log}
            // onMouseLeave={console.log}
            rx={5}
            shape={<ShapeChartBar cursor={onClick ? 'pointer' : undefined} />}
            width={30}
          />
          {avg && (
            <ReferenceLine
              fontSize={11}
              label={
                <ReferenceLineLabel
                  value={numberFormat(+(avg ?? 0), valuesOptions)}
                />
              }
              stroke={theme.palette.secondary.dark}
              strokeWidth={2}
              y={avg}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

const ChartUniqueBar: React.FC<IChartUniqueBar> = ({
  name,
  value,
  avg,
  max,
  onClick,
  valuesOptions,
}) => (
  <ChartBar
    avg={avg}
    max={max}
    onClick={onClick}
    values={[
      {
        name,
        value: +(value ?? 0),
      },
    ]}
    valuesOptions={valuesOptions}
  />
);

export default ChartUniqueBar;
