import { useMemo, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';

import { IHerdCompositionOverviewResponse } from '../../../../resources/animal';

interface IProps {
  values: IHerdCompositionOverviewResponse | undefined;
}

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#94A352',
  '#FF8042',
  '#3B5E32',
  '#F0D591',
];

const renderActiveShape = (
  props: any,
  calculateTotal: string,
  fill: string
) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = props;

  return (
    <g>
      <text
        dy={8}
        fill="#333"
        fontSize={14}
        textAnchor="middle"
        x={cx}
        y={cy - 12}
      >
        Total
      </text>
      <text
        dy={8}
        fill="#333"
        fontSize={15}
        fontWeight="bold"
        textAnchor="middle"
        x={cx}
        y={cy + 8}
      >
        {calculateTotal} animais
      </text>
      <Sector
        cx={cx}
        cy={cy}
        endAngle={endAngle}
        fill={fill}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
      />
    </g>
  );
};

export const HerdCompositionChart = ({ values }: IProps) => {
  const [filterBy, setFilterBy] = useState<'female' | 'male'>('female');

  const dataFormatted = useMemo(
    () => Object.values(values?.[filterBy] ?? {}) || [],
    [values, filterBy]
  );

  const percentageByFilter = useMemo(() => {
    const total = {
      male: ((values?.totalMale ?? 0) / (values?.totalAnimals || 0)) * 100,
      female: ((values?.totalFemale ?? 0) / (values?.totalAnimals || 0)) * 100,
    };

    return total;
  }, [values]);

  const calculateTotal = useMemo(
    () => dataFormatted?.reduce((acc, item) => acc + item.value, 0),
    [dataFormatted]
  );

  const sections = useMemo(
    () =>
      dataFormatted?.map((item) => ({
        value: (item.value / calculateTotal) * 100 || 0,
        name: item.description,
      })) ?? [],
    [dataFormatted, calculateTotal]
  );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          marginBottom: 20,
          gap: 10,
        }}
      >
        <Box
          sx={{
            borderBottom: filterBy === 'female' ? '2px solid' : 'none',
            borderColor: 'primary.main',
          }}
        >
          <Button onClick={() => setFilterBy('female')}>
            Fêmeas {percentageByFilter.female?.toFixed(0)}%
          </Button>
        </Box>

        <Box
          color="text.primary"
          sx={{
            borderLeft: '1px solid',
            borderColor: 'primary.main',
            height: 20,
            alignSelf: 'center',

            backgroundColor: 'primary.main',
          }}
        />

        <Box
          sx={{
            borderBottom: filterBy === 'male' ? '2px solid' : 'none',
            borderColor: 'primary.main',
          }}
        >
          <Button onClick={() => setFilterBy('male')}>
            Machos {percentageByFilter.male?.toFixed(0)}%
          </Button>
        </Box>
      </div>

      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
      >
        <Box
          height={270}
          maxWidth={400}
          minWidth={120}
          width="100%"
        >
          <ResponsiveContainer
            height="100%"
            width="100%"
          >
            <PieChart
              height={250}
              width={200}
            >
              <Pie
                activeIndex={0}
                activeShape={(props: any) =>
                  renderActiveShape(
                    props,
                    calculateTotal,
                    COLORS[0 % COLORS.length]
                  )
                }
                data={sections}
                dataKey="value"
                innerRadius={60}
                label={({ value }) => (value > 0 ? `${value.toFixed(0)}%` : '')}
                outerRadius={80}
                paddingAngle={2}
              >
                {sections.map((entry, index) => (
                  <Cell
                    fill={COLORS[index % COLORS.length]}
                    key={`cell-${index}`}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>
        {/* <Typography
          sx={{
            color: 'success.main',
            fontWeight: 'normal',
            position: 'absolute',
            textAlign: 'center',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          Total <br />
          <b>{calculateTotal} animais</b>
        </Typography> */}

        <Box>
          {dataFormatted?.map((data, index) => (
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              gap={2}
              key={index}
            >
              <Box
                bgcolor={COLORS[index % COLORS.length]}
                borderRadius="50%"
                height={10}
                width={10}
              />
              <Typography
                sx={{
                  color: 'text.secondary',
                }}
              >
                <b>{data.value?.toFixed() ?? 0}</b> {data.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};
