import pkg from '../../package.json';
import { withProductSecurity } from '../helpers/token';
import Http from '../services/http';
import { IPagination } from './pagination';
import { IResourceResponse } from './response';

interface IUserAuthPayload {
  username: string;
  password: string;
}

interface IUserRequestPasswordRecoveryPayload {
  username: string;
}

interface IUserConfirmNewPasswordPayload {
  username: string;
  password: string;
  confirmationCode: string;
}

interface IUserAcceptReportDownloadPayload {
  fromDate: string | null;
  toDate: string | null;
  codCenters: string[] | null;
}

interface INotificationUserPayload {
  centers: string[];
  userType: string[];
}

interface INotificationUserResponse {
  id: string;
  name: string;
  cpf: string;
  typeUser: string;
  codCenter: string;
}
interface IUserAuthPayload {
  username: string;
  password: string;
}

interface IUserRequestPasswordRecoveryPayload {
  username: string;
}

interface IUserConfirmNewPasswordPayload {
  username: string;
  password: string;
  confirmationCode: string;
}

interface IUserAcceptReportDownloadPayload {
  fromDate: string | null;
  toDate: string | null;
  codCenters: string[] | null;
}
interface IUserActiveReportDownloadPayload {
  fromDate: string | null;
  toDate: string | null;
  codCenters: string[] | null;
}
interface INotificationUserPayload {
  centers: string[];
  userType: string[];
}

interface ITypesTotalsPayload {
  fromDate: string | null;
  toDate: string | null;
  codCenters: string[] | null;
}

export interface IUserListPayload {
  page: number;
  typeUsers: TypeUser[];
  codCenters: string[] | null;
  activeInApp: boolean;
  query: string;
}

interface IUserCenter {
  id: string;
  name: string;
}

interface IUserAuthResponse {
  token: string;
  user: {
    name: string;
    roles: string[];
    identity: string;
    centers: IUserCenter[];
  };
}

interface INotificationUserResponse {
  id: string;
  name: string;
  cpf: string;
  typeUser: string;
  codCenter: string;
}

export type TypeUser =
  | 'provider'
  | 'supervisor'
  | 'purchasingSupervisor'
  | 'qualitySupervisor';

export interface ITypesTotals {
  totalInBase: {
    typeUser: TypeUser;

    activeInBase: number;
  }[];
  totalInApp: {
    typeUser: TypeUser;
    activeInApp: number;
    inactiveInApp: number;
  }[];
  // accessedInPeriod: [];
}

export interface IUser {
  id: string;
  name: string;
  cpf: string;
  codCenter: string;
  userNameCognito: string;
  lastLogin: string;
  versionApp: string;
  register: string;
  invitation: string;
  email: string;
  phone: string;
  typeUser: 'provider' | 'supervisor';
}

export interface IUserData {
  userData: {
    id: string;
    name: string;
    dateBirth: string;
    email: string;
    isActive: boolean;
    cpf: string;
    code: string;
    phone: string;
    invitation: boolean;
  };
  propertyData: {
    id: string;
    typeProduction: string;
    cnpj: string;
    supplierCode: string;
    city: string;
    state: string;
    country: string;
    totalArea: string;
    areaDairyActivity: string;
    routeDescription: string;
    numberProducerRural: string;
    identifierProducer: string;
  }[];
}

export interface ISupervisorPayload {
  centers?: string[];
}
export interface IUserByCpfPayload {
  cpf: string;
  codCenters: string[] | null;
}

export interface ISupervisor {
  id: string;
  name: string;
  email: string;
  phone: string;
  cpf: string;
  typeSupervisor: 'purchasingSupervisor' | 'qualitySupervisor';
  code: string;
  properties: {
    id: string;
    codCenter: string;
  }[];
}

export interface IUserResources {
  auth({
    username,
    password,
  }: IUserAuthPayload): Promise<IResourceResponse<IUserAuthResponse>>;
  requestPasswordRecovery({
    username,
  }: IUserRequestPasswordRecoveryPayload): Promise<
    IResourceResponse<IUserAuthResponse>
  >;
  confirmNewPassword({
    username,
  }: IUserConfirmNewPasswordPayload): Promise<
    IResourceResponse<IUserAuthResponse>
  >;
  userAcceptReportDownload({
    fromDate,
    toDate,
    codCenters,
  }: Partial<IUserAcceptReportDownloadPayload>): Promise<
    IResourceResponse<any>
  >;
  userActiveReportDownload({
    fromDate,
    toDate,
    codCenters,
  }: Partial<IUserActiveReportDownloadPayload>): Promise<
    IResourceResponse<any>
  >;

  notificationUsers({
    centers,
    userType,
  }: INotificationUserPayload): Promise<
    IResourceResponse<INotificationUserResponse[]>
  >;
  userTypesTotals({
    fromDate,
    toDate,
    codCenters,
  }: Partial<ITypesTotalsPayload>): Promise<IResourceResponse<ITypesTotals>>;
  usersByCodCenter({
    codCenters,
    typeUsers,
    page,
  }: Partial<IUserListPayload>): Promise<IResourceResponse<IPagination<IUser>>>;
  userDataByCpf({
    cpf,
    codCenters,
  }: IUserByCpfPayload): Promise<IResourceResponse<IUserData>>;
  getSupervisors({
    centers,
  }: ISupervisorPayload): Promise<IResourceResponse<ISupervisor[]>>;
}

const user: IUserResources = {
  async auth({ username, password }) {
    const { data, status } = await Http.post(
      `${process.env.REACT_APP_API_USER_URL}/user/login`,
      {
        userName: username,
        password,
        versionApp: {
          app: 'piracanjuba',
          version: '2.2.1',
          os: 'web',
          versionOs: '0',
        },
      }
    );
    if (status === 200) {
      Http.defaults.headers.common.Authorization = `Basic ${data.idToken}`;

      const [{ data: userIdentityData }] = await Promise.all([
        Http.get(`${process.env.REACT_APP_API_USER_URL}/user/${data.idUser}`),
      ]);

      return {
        status: 'OK',
        payload: {
          token: data.idToken,
          user: {
            name: userIdentityData?.name ?? username,
            roles: data.permissions?.map(({ group }: any) => group) ?? [],
            identity: userIdentityData.identity,
            centers: (userIdentityData.properties ?? []).map(
              ({ codCenter }: any) => ({
                id: codCenter,
                name: codCenter,
              })
            ),
          },
        },
      };
    }

    return {
      status: 'FAIL',
    };
  },
  async requestPasswordRecovery({ username }) {
    const { status, data } = await Http.patch(
      `${process.env.REACT_APP_API_ENTITLEMENT_URL}/user/password-recovery-request`,
      withProductSecurity({
        login: username,
      })
    );

    if (status === 200) {
      return {
        status: 'OK',
        message: data.message,
      };
    }

    return {
      status: 'FAIL',
      message: data.error,
    };
  },
  async confirmNewPassword({ username, password, confirmationCode }) {
    const { status, data } = await Http.patch(
      `${process.env.REACT_APP_API_ENTITLEMENT_URL}/user/password-recovery`,
      withProductSecurity({
        login: username,
        password,
        confirm_password: password,
        code: confirmationCode,
      })
    );
    if (status === 200) {
      return {
        status: 'OK',
        message: data.message,
      };
    }
    return {
      status: 'FAIL',
      message: data.error,
    };
  },
  async notificationUsers({ centers, userType }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_USER_URL}/userNotification`,
      {
        params: {
          limit: 10000000,
          ...(centers.length > 0
            ? {
                codCenter: centers.join(','),
              }
            : {}),
          ...(userType.length > 0
            ? {
                typeUser: userType.join(','),
              }
            : {}),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data?.data ?? [],
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async userAcceptReportDownload({ fromDate, toDate, codCenters }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_USER_URL}/reports/userAccept`,
      {
        responseType: 'blob',
        params: {
          fromDate,
          toDate,
          codCenter: codCenters?.join(','),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async userActiveReportDownload({ codCenters, fromDate, toDate }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_USER_URL}/reports/userActive`,
      {
        responseType: 'blob',
        params: {
          fromDate,
          toDate,
          codCenter: codCenters?.join(','),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async userTypesTotals({ fromDate, toDate, codCenters }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_USER_URL}/usersTypesTotalByCodCenter`,
      {
        params: {
          dateFrom: fromDate,
          dateTo: toDate,
          codCenter: codCenters?.join(','),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data,
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async usersByCodCenter({ typeUsers, codCenters, activeInApp, query, page }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_USER_URL}/usersListByCodCenter`,
      {
        params: {
          typeUser: typeUsers?.join(','),
          codCenter: codCenters?.join(','),
          page,
          ...(query ? { dataUser: query } : {}),
          ...(activeInApp ? { activeInApp } : {}),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: {
          data: data.data,
          meta: {
            limit: data.meta?.limit ?? 0,
            page: data.meta?.page ?? 0,
            pages: data.meta?.pages ?? 0,
            records: data.meta?.records ?? 0,
          },
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async userDataByCpf({ cpf, codCenters }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_USER_URL}/userDataByCpf`,
      {
        params: {
          cpf,
          codCenter: codCenters?.join(','),
        },
      }
    );

    if (status === 200) {
      const [userData] = data.userData;
      return {
        status: 'OK',
        payload: {
          userData: {
            id: userData.id,
            name: userData.name,
            dateBirth: userData.dateBirth,
            email: userData.email,
            isActive: userData.isActive,
            cpf: userData.cpf,
            code: userData.codePiracanjuba,
            phone: userData.phone,
            invitation: userData.invitation,
          },
          propertyData: data.propertyData,
        },
      };
    }
    return {
      status: 'FAIL',
    };
  },
  async getSupervisors({ centers }) {
    const { status, data } = await Http.get(
      `${process.env.REACT_APP_API_USER_URL}/userSupervisorList`,
      {
        params: {
          codCenter: centers?.join(','),
        },
      }
    );

    if (status === 200) {
      return {
        status: 'OK',
        payload: data.map(({ codePiracanjuba, ...supervisor }: any) => ({
          ...supervisor,
          code: codePiracanjuba,
        })),
      };
    }
    return {
      status: 'OK',
      payload: [],
    };
  },
};

export default user;
